<template>
  <div class="about">
    <div class="row justify-content-start">
      <div class="col-2"></div>
      <div class="col-6" style="margin: 50px 0px 0px 0px;">
        <img src="../assets/img/about-1.jpg" />
        <img src="../assets/img/about-2.jpg" />
        <img src="../assets/img/about-3.jpg" />
        <img src="../assets/img/about-4.jpg" />
        <img src="../assets/img/about-5.jpg" />
      </div>
      <div class="col-2">
        <v-side style="width:240px; height:600px; margin: 50px 0px 0px 0px;" />
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>
<script>
import Side from '../components/Side'
import data from '../config/index'
export default {
  components: {
    'v-side': Side
  },
  data() {
    return {
      imageUrl: data.imageUrl
    }
  }
}
</script>